import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import Router from "./router";
import Store from "./store";


import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

import 'ant-design-vue/dist/antd.less';

import "@/layout/global.less";

const app = createApp(App);

app.use(Router);
app.use(Store);
app.use(Antd);
app.use(mavonEditor);
app.mount("#app");