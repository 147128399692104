<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import { defineComponent } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
// import enUs from "ant-design-vue/es/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

export default defineComponent({
  name: "App",
  setup() {
    return { zhCN };
  },
});
</script>
