<template>
  <a-layout class="site-page">
    <a-layout-sider :width="menuStatusParam.second_menus.length > 0 ? 260:120" class="site-sider" v-model:collapsed="collapsed">
      <div class="sider-main">
        <div class="site-logo">
        </div>
        <a-menu mode="inline" theme="dark" v-model:selectedKeys="menuStatusParam.sub_selected_key">
          <template v-for="item in menuStatusParam.sub_menus">
            <a-menu-item v-if="item.show" :key="item.id">
              <template #icon>
                <MyIcon :type="item.meta.icon" class="nav-icon" />
              </template>
              <router-link :to="item.path">{{ item.title }}</router-link>
            </a-menu-item>
          </template>
        </a-menu>
      </div>
      <div class="sider-second" v-if="menuStatusParam.second_menus.length > 0">
        <a-menu mode="inline" theme="light" v-model:selectedKeys="menuStatusParam.selected_key" :open-keys="menuStatusParam.open_keys">
          <template v-for="(item) in menuStatusParam.second_menus">
            <template v-if="item.show">
              <a-sub-menu v-if="item.children && item.children.length > 0" :key="item.id">
                <template #title>{{ item.title }}</template>
                <template v-for="items in item['children']">
                  <a-menu-item v-if="items.show" :key="items.id">
                    <router-link :to="items.path">{{ items.title }}</router-link>
                  </a-menu-item>
                </template>
              </a-sub-menu>
              <a-menu-item v-else :key="item.id">
                <router-link :to="item.path">{{ item.title }}</router-link>
              </a-menu-item>
            </template>
          </template>
        </a-menu>
      </div>

    </a-layout-sider>
    <a-layout>
      <a-layout-header class="site-header">
        <div class="site-icon-box">
          <MenuFoldOutlined class="header-icon" @click="goback" />
          <ReloadOutlined class="header-icon" />
        </div>
        <a-breadcrumb class="site-breadcrumb">
          <template v-for="(item, index) in localNav" :key="index">
            <a-breadcrumb-item v-if="index > 0 && localNav.length > 2">{{ item.meta.title }}</a-breadcrumb-item>
          </template>
        </a-breadcrumb>
        <div class="site-setting">
          <a-dropdown class="setting-item notice-item" placement="bottomLeft">
            <BellOutlined />
          </a-dropdown>
          <a-dropdown class="setting-item avatar-item" placement="bottomLeft">
            <a-button type="text">{{ admin_user }}
              <DownOutlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;" @click="updatePassWord">修改密码</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click.stop="logout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content>
        <div class="site-body">
          <router-view></router-view>
          <div class="site-footer">
            <img src="@/assets/img/logo-gray.png" class="footer-logo" alt="logo">
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import {
  createFromIconfontCN,
  MenuFoldOutlined,
  ReloadOutlined,
  BellOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computedMenuStatus } from "@/utils/common";
import { createRouteByList } from "@/router/navigation";
import routes from "@/router/routes";

const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3579089_ihlfk61qfq8.js", // 在 iconfont.cn 上生成
});

export default defineComponent({
  components: {
    MyIcon,
    MenuFoldOutlined,
    ReloadOutlined,
    BellOutlined,
    DownOutlined,
  },

  setup() {
    const route = useRoute();
    const store = useStore();

    const admin_user = ref(store.state.user.username);

    // 获取本地固定路由下所有路由信息
    // const menus = createRouteByList(routes[0].children);
    //动态获取菜单，一级选中的key，侧边选中的key，打开的key
    const menuStatusParam = computed(() => {
      return computedMenuStatus(
        createRouteByList(routes[0].children),
        route.path
      );
    });

    // 注销登录
    const logout = () => {
      store.dispatch("user/logout");
      router.push({ path: "/login" });
      localStorage.clear();
    };

    // 返回上一页
    const goback = () => {
      router.go(-1);
    };
	//修改密码
	const updatePassWord = () => {
		router.push({ path: "/manage/system/user" });
	}

    const localNav = computed(() => {
      return route.matched;
    });

    return {
      admin_user,
      localNav,
      logout,
      goback,
	updatePassWord,
      menuStatusParam,
      collapsed: ref(false),
    };
  },
});
</script>
