import { auth_login } from "@/store/api";
import { message } from "ant-design-vue";

const state = {
  token: localStorage.getItem("ACCESS_TOKEN") != null ? localStorage.getItem("ACCESS_TOKEN") : "",
  username: localStorage.getItem("USER_NAME") != null ? localStorage.getItem("USER_NAME") : "",
  userauth: localStorage.getItem('USER_AUTH') != null ? localStorage.getItem('USER_AUTH') : null,
}

const mutations = {
  SET_ACCESS_TOKEN(state, token) {
    if (token) {
      state.token = token;
      localStorage.setItem('ACCESS_TOKEN', token);
    } else {
      state.token = '';
      localStorage.removeItem('ACCESS_TOKEN')
    }
  },
  SET_USER_NAME(state, username) {
    state.username = username
    localStorage.setItem('USER_NAME', username)
  },
  SET_USER_AUTH(state, userauth) {
    state.userauth = userauth
    localStorage.setItem('USER_AUTH', userauth)
  }
}

const actions = {
  logout({ commit }) {
    // await logout() 请求后端记录退出操作
    message.success("注销成功", 0.5).then(function () {
      commit('SET_ACCESS_TOKEN');
      commit('SET_USER_NAME');
      commit('SET_USER_AUTH');
    });
    return Promise.resolve();
  },
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      auth_login(data).then((res) => {
        //登录成功，保存token,用户信息,栏目权限
        if (res.status === 200) {
          commit('SET_ACCESS_TOKEN', res.data.token);
          commit('SET_USER_NAME', res.data.user_name);
          commit('SET_USER_AUTH', res.data.user_auth);
          location.reload();
          return resolve();
        } else {
          message.error(res.message);
          return resolve();
        }
      }).catch(err => {
        return reject(err);
      })
    })
  },
}

export default {
  namespaced: true,
  mutations,
  actions,
  state
}