/**
 * 根据 当前路径 查询 菜单编号
 * 
 * @param arr 菜单列表
 * @param id  当前路由
 */
export const computedMenuStatus = (arr, active_path) => {

    // 获取所有路由
    let temp = [];

    //获取当前活动路由key
    let selected_key = 0;

    let forFn = function (menus, parent_id) {
        menus.forEach((item) => {
            if (item.path === active_path) {
                selected_key = item.id;
            }
            temp.push({
                path: item.path,
                name: item.name,
                show: item.show,
                title: item.title,
                id: item.id,
                parent_id: parent_id,
                meta: item.meta,
                // hidden: item.hidden
            });
            if (item.children) {
                forFn(item.children, item.id);
            }
        });
    }
    forFn(arr, 10000);

    //一级菜单被选中的key
    let sub_selected_key = [];

    let forFnActive = function (temp, id) {
        temp.forEach((it) => {
            if (it.id === id) {
                if (it.parent_id !== 10000) {
                    forFnActive(temp, it.parent_id);
                } else {
                    sub_selected_key.push(it.id);
                }
            }
        })
    }
    forFnActive(temp, selected_key);

    // 获取当前活动key上级所有key(除一级外)
    let open_keys = [];

    //一级顶部导航菜单
    let sub_menus = [];
    //二级侧边栏导航菜单
    let second_menus = [];

    arr.forEach((item) => {
        sub_menus.push({
            path: item.path,
            name: item.name,
            show: item.show,
            title: item.title,
            id: item.id,
            parent_id: item.parent_id,
            meta: item.meta,
            // hidden: item.hidden
        });
        if (item.children && item.id == sub_selected_key) {
            second_menus = item.children;
        }
    })

    let forFnSecondMenus = function (temp) {
        temp.forEach((item) => {
            if (item.children) {
                // 获取侧边栏导航所有父级菜单，openkeys
                open_keys.push(item.id);
                forFnSecondMenus(item.children);
            }
        })
    }

    forFnSecondMenus(second_menus);

    // console.log(11111111111);
    // console.log('当前key：', [selected_key]);
    // console.log(sub_menus);
    // console.log('打开的keys：', open_keys);
    // console.log('主栏目的key：', sub_selected_key);
    // console.log('二级菜单：', second_menus);

    return { selected_key: [selected_key], sub_selected_key, open_keys, second_menus, sub_menus };
}