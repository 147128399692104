import { createLogger, createStore } from 'vuex'

const files = require.context('./modules', false, /\.js$/)

const modules = {}
files.keys().forEach(key => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

import getters from './getters.js'

const debug = false;

export default createStore({
  modules,
  getters,
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
