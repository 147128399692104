const state = {
    loading: false
}

const mutations = {
    SHOW_LOADING(state, load) {
        state.loading = load;
    }
}

const actions = {
    setloading({ commit }, load) {
        commit('SHOW_LOADING', load);
    },
}

export default {
    namespaced: true,
    mutations,
    actions,
    state
}